<template>
  <WidgetLayout :payload="data"/>
</template>

<script>
import WidgetLayout from "@/components/WidgetLayout.vue"
import creez from '../../../public/json/decret-tertiaire-creez.json'
import slides from '../../../public/json/decret-tertiaire-slides.json'

export default {
  name: "create-your-action-plan",
  components: {
    WidgetLayout
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez comment prioriser les actions à mener pour réduire vos consommations et vous mettre en conformité avec le décret tertiaire.'
    }]
  },
  computed: {
    data() {
      return creez.map(m => {
        if (m.type === 'slider') {
          return {
            slides: slides,
            ...m
          }
        } else {
          return {
            ...m
          }
        }
      })
    }
  }
}
</script>